import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import theme from './theme';

import NoticeModal from 'components/NoticeModal';
import { localeMap, localeUtilsMap } from 'utils/LocaleUtils';
import Routes from './Routes';

const locale = 'pt';

function App() {
  return (
    <React.Fragment>
      <NoticeModal />
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider
          locale={localeMap[locale]}
          utils={localeUtilsMap[locale]}
        >
          <Router basename={process.env.PUBLIC_URL}>
            <Routes />
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
export { locale };
