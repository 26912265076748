import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore } from 'redux';

import rootSaga from './sagas';
import reducers from './reducers';

const sagaMiddleware = createSagaMiddleware();

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const store = applyMiddleware(sagaMiddleware)(createStore)(reducers, devTools);

sagaMiddleware.run(rootSaga);

export default store;
