/* eslint-disable no-undef */

const get = () => {
  const storage = localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_KEY);

  if (null !== storage) {
    return JSON.parse(storage);
  }

  return null;
};

const set = (data) => {
  localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_KEY, JSON.stringify(data));
};

const addKey = (key, value) => {
  let storage = get();

  if (storage === null) {
    storage = {};
  }

  storage[key] = value;

  set(storage);
};

const removeKey = (key) => {
  const storage = get();
  delete storage[key];

  set(storage);
};

const remove = () => {
  localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_KEY);
}

export default {
  get,
  set,
  addKey,
  removeKey,
  remove,
}
