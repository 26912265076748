const initialState = {
  list: [],
  selected: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SUCCESS_FIND_PROJECTS':
      return { ...state, list: action.payload };
    case 'SUCCESS_SELECTED_PROJECT':
      return { ...state, selected: action.payload };
    case 'RESET_FIND_PROJECTS':
      return { ...initialState };
    default:
      return state;
  }
}
