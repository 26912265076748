const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

const getErrorList = (error) => {
  const response = error.response;

  if (response && response.status >= 400) {
    const data = response.data;
    const errors = data.errors;

    if (errors && Array.isArray(errors)) {
      const list = [];

      errors.forEach((err) => {
        list.push({
          defaultMessage: err.defaultMessage,
          field: err.field,
          objectName: err.objectName,
          rejectValue: err.rejectValue
        });
      });

      return list;
    }
  }
}

const getError = (field, errors) => {
  if (!errors || errors.length === 0) {
    return undefined;
  }

  const error = errors.filter((error) => error.field === field);

  if (!error || error.length === 0) {
    return undefined;
  }

  return capitalize(error[0].defaultMessage);
}

const hasError = (field, errors) => getError(field, errors) ? true : false;

export default { getError, getErrorList, hasError };
