import React from 'react';
import PropTypes from 'prop-types';
import { Button as MaterialUiButton } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './styles';

const StandardWhiteButton = ({ className, color, disabled, onClick, text }) => {
  const classes = useStyles();

  return (
    <MaterialUiButton
      className={clsx(classes.root, className)}
      disabled={disabled}
      onClick={onClick}
      variant="contained"
      style={{ textAlign: 'left' }} // Alinha o botão à esquerda
    >
      <b style={{ fontFamily: 'BradescoSans-Bold' }}>{text}</b>
    </MaterialUiButton>
  );
};

StandardWhiteButton.propTypes = {
  className: PropTypes.any,
  color: PropTypes.oneOf(['black', 'inherit']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

StandardWhiteButton.defaultProps = {
  color: 'inherit',
  disabled: false,
  onClick: () => {},
};

export default StandardWhiteButton;
