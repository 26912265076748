import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteWithLayout = props => {
  const { layoutProps, layout: Layout, scheduling, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout layoutProps={layoutProps}>
          <Component
            {...matchProps}
            scheduling={scheduling}
          />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  layoutProps: PropTypes.shape({
    showShield: PropTypes.bool,
    logoAlign: PropTypes.oneOf(['center', 'right']),
  }),
  path: PropTypes.string,
  scheduling: PropTypes.shape({}),
};

export default RouteWithLayout;
