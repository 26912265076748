import { makeStyles } from '@material-ui/core';

import backgroundImg from 'images/bg/desktop/background.png';
import backgroundImgMobile from 'images/bg/mobile/bg.png';

export default makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    justifyContent: 'center',
    minHeight: '100%',
    backgroundColor: theme.palette.neutral,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${backgroundImg})`,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      backgroundImage: `url(${backgroundImgMobile})`,
    },
  },
  top: {
    flex: 1,
    height: '100%',
    paddingTop: theme.spacing(2),
    position: 'relative',
    textAlign: 'right',
    width: '100%',
    paddingRight: '35px',
  },
  topLeftImg: {
    height: 'auto',
    left: 0,
    position: 'absolute',
    width: '40%',
    [theme.breakpoints.up('md')]: {
      width: '25%',
    },
  },
  logo: {
    height: 'auto',
    width: '20%',
    [theme.breakpoints.up('md')]: {
      width: '7%',
    },
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    zIndex: '99',
  },
  bottomRightImg: {
    bottom: '0',
    right: '0',
    height: 'auto',
    position: 'absolute',
    width: '30%',
    [theme.breakpoints.up('md')]: {
      width: '20%',
    },
  },
  bottomLeftImg: {
    bottom: '0',
    left: '0',
    height: 'auto',
    position: 'absolute',
    width: '40%',
    [theme.breakpoints.up('md')]: {
      width: '20%',
    },
  },
  logoRightAlign: {
    right: '5%',
  }
}));
