import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PrivateRouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;

  const customer = useSelector(state => state.customer);

  return (
    <Route
      {...rest}
      render={matchProps => (
        customer?.id !== null ?
          <Layout>
            <Component {...matchProps} />
          </Layout>
          : <Redirect to="/welcome" />
      )}
    />
  );
};

PrivateRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default PrivateRouteWithLayout;
