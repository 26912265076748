/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: theme.spacing(3)
  },
  error: {
    color: theme.palette.common.white,
    fontSize: '1rem',
    fontWeight: 500,
    marginTop: theme.spacing(1)
  },
}));

const FieldError = ({ align, container, message }) => {
  const classes = useStyles();

  return container ?  
    <Grid className={classes.root} item xs={12}>
      <Typography align={align} className={classes.error} display="block" variant="caption">{message}</Typography>
    </Grid> : 
    <Typography align={align} className={classes.error} display="block" variant="caption">{message}</Typography>
}

FieldError.propTypes = {
  align: PropTypes.string,
  container: PropTypes.bool,
  message: PropTypes.string
}

FieldError.defaultProps = {
  align: 'center',
  container: false,
  message: null
}

export default FieldError;
