/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Grid, Snackbar } from '@material-ui/core';

import logo from 'images/logos/simbolo-bradesco-vermelho.png';
import topLeftImg from 'images/bg/desktop/grafismo-esquerdo-superior-vermelho.png';
import bottomRightImg from 'images/bg/desktop/grafismo-direito-inferior.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    minHeight: '100%',
    overflowY: 'auto',
    backgroundColor: 'white',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'relative',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '10%',
    paddingRight: '8%',
    paddingTop: '35%',
    zIndex: '99',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0%',
      paddingRight: '0%',
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '20%',
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '10%',
    }
  },
  topLeftImg: {
    top: '0%',
    left: '60%',
    height: 'auto',
    position: 'absolute',
    width: '40%',
    transform: 'scaleX(-1)',
    [theme.breakpoints.up('lg')]: {
      top: '0%',
      width: '15%',
      transform: 'scaleX(1)',
      left: '0%',
    },
  },
  bottomRightImg: {
    height: 'auto',
    position: 'fixed',
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      width: '25%',
      top: '60%',
      left: '75%',
      display: 'block',
    },
  },
  logo: {
    top: '4%',
    left: '8%',
    height: 'auto',
    position: 'absolute',
    width: '20%',
    [theme.breakpoints.up('lg')]: {
      left: '88%',
      width: '7%',
    },
  },
}));

function Layout4({ children }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const snack = useSelector((state) => state.snackbar);

  const closeSnackbar = () => {
    dispatch({ type: 'CLOSE_SNACKBAR' });
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <img alt="" className={classes.logo} src={logo} />
        <img alt="" className={classes.topLeftImg} src={topLeftImg} />
        <img alt="" className={classes.bottomRightImg} src={bottomRightImg} />
        <Grid className={classes.content} item style={{ 'width': '100%'}} xs={12}>
          {children}
        </Grid>
      </div>
      <Snackbar
        autoHideDuration={6000}
        message={snack.message}
        onClose={closeSnackbar}
        open={snack.show}
      />
    </React.Fragment>
  );
}

Layout4.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Layout4;
