/* eslint-disable no-undef */

const get = () => {
  const storage = localStorage.getItem(process.env.REACT_APP_PRINT_STORAGE_KEY);

  if (null !== storage) {
    return JSON.parse(storage);
  }

  return null;
};

const set = (data) => {
  localStorage.setItem(process.env.REACT_APP_PRINT_STORAGE_KEY, JSON.stringify(data));
};

export default {
  get,
  set,
}
