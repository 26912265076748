/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { StandardButton } from 'components';
import { Typography } from '@material-ui/core';

import alertIcon from 'images/icon_alert.svg';
import smsIcon from 'images/icon_sms.svg';
import likeIcon from 'images/icon_like.svg';

const useStyles = makeStyles(theme => ({
  root: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    height: 'auto',
    left: 0,
    minHeight: '100%',
    opacity: '.95',
    padding: '4rem 2rem',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  iconBox: {
    marginBottom: '2.5rem',
    textAlign: 'center',
  },
  icon: {
    height: 'auto',
    width: '8rem',
  },
  description: {
    color: theme.palette.white,
    lineHeight: '1.5',
  },
  message: {
    color: theme.palette.white,
    fontWeight: 'bold',
    lineHeight: '1.75',
  },
  buttonBox: {
    marginTop: '3rem',
    textAlign: 'center',
  },
}));

const SimpleAlert = ({ description, icon, message, onClick, textButton, visible }) => {
  const classes = useStyles();

  const getIcon = (name) => {
    switch (name) {
      case 'alert':
        return alertIcon;
      case 'sms':
        return smsIcon;
      case 'like':
        return likeIcon;
      default:
        return alertIcon;
    }
  }

  return (
    <div className={classes.root} style={{ display: visible ? 'block' : 'none' }}>
      <div className={classes.iconBox}>
        <img alt="" className={classes.icon} src={getIcon(icon)} />
      </div>
      <Typography align="center" className={classes.message} display="block" variant="h2">{message}</Typography>
      <Typography align="center" className={classes.description} display="block" variant="h4">{description}</Typography>
      <div className={classes.buttonBox}>
        <StandardButton onClick={onClick} text={textButton} />
      </div>
    </div>
  )
}

SimpleAlert.propTypes = {
  description: PropTypes.string.isRequired,
  icon: PropTypes.string,
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  textButton: PropTypes.string,
  visible: PropTypes.bool,
};

SimpleAlert.defaultProps = {
  icon: 'alert',
  textButton: 'OK',
  visible: false,
}

export default SimpleAlert;
