/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Snackbar } from '@material-ui/core';

import logo from 'images/logos/logo-bradesco.png';
import topLeftImg from 'images/bg/desktop/grafismo-esquerdo-superior.png';
import bottomRightImg from 'images/bg/desktop/grafismo-lado-direito-inferior.png';
import bottomLeftImg from 'images/bg/desktop/brasao-de-protecao.png';

import useStyles from './styles';
import { If } from 'helpers';
import clsx from 'clsx';

function Main({ children, layoutProps }) {

  const classes = useStyles();

  const dispatch = useDispatch();
  const snack = useSelector((state) => state.snackbar);

  const [logoProps, setLogoProps] = React.useState({ className: classes.logo });

  React.useEffect(() => {
    const clazz = layoutProps.logoAlign === 'right' ? classes.logoRightAlign : null;

    setLogoProps((values) => ({ ...values, className: clazz }));
  }, [classes.logoRightAlign, layoutProps]);

  const closeSnackbar = () => {
    dispatch({ type: 'CLOSE_SNACKBAR' });
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.top}>
          <img alt="" className={classes.topLeftImg} src={topLeftImg} />
          <img alt="" className={clsx(classes.logo, logoProps.className)} src={logo} />
        </div>
        <img alt="" className={classes.bottomRightImg} src={bottomRightImg} />
        <If test={layoutProps.showShield}>
          <img alt="" className={classes.bottomLeftImg} src={bottomLeftImg} />
        </If>
        <Grid className={classes.content} item style={{ 'width': '100%' }} xs={12}>
          {children}
        </Grid>
      </div>
      <Snackbar
        autoHideDuration={6000}
        message={snack.message}
        onClose={closeSnackbar}
        open={snack.show}
      />
    </React.Fragment>
  );
}

Main.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  layoutProps: PropTypes.shape({
    showShield: PropTypes.bool,
    logoAlign: PropTypes.oneOf(['center', 'right']),
  }),
};

Main.defaultProps = {
  layoutProps: {
    logoAlign: 'center',
    showShield: true,
  }
};

export default Main;
