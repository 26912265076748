import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { TextField as MaterialUiTextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: `${theme.palette.common.white} !important`,
    borderRadius: 20,
  }
}));

const TextField = ({ autoFocus, error, fontSize, label, name, onChange, placeholder, required, textAlign, type, value }) => {
  const classes = useStyles();

  return (
    <MaterialUiTextField
      autoFocus={autoFocus}
      className={classes.root}
      error={error}
      fullWidth
      inputProps={{ style: { fontSize: fontSize, 'textAlign': textAlign } }}
      label={label}
      margin="dense"
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      type={type}
      value={value}
      variant="outlined"
    />
  );
};

TextField.propTypes = {
  autoFocus: PropTypes.bool,
  error: PropTypes.bool.isRequired,
  fontSize: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  textAlign: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any.isRequired,
}

TextField.defaultProps = {
  autoFocus: false,
  fontSize: '1.5rem',
  label: null,
  name: 'textfield',
  onChange: () => {},
  placeholder: '',
  required: false,
  textAlign: 'left',
  type: 'text',
}

export default TextField;
