/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button as MaterialUiButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    border: '1px solid #fff',
    borderRadius: 30,
    fontWeight: 600,
    fontSize: '1.5rem',
    height: '3.5rem',
    textTransform: 'inherit',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  }
}));

const StandardButton = ({ disabled, marginBottom, marginRight, onClick, opacity, padding, text, width }) => {
  const classes = useStyles();

  return (
    <MaterialUiButton
      className={classes.root}
      color="primary"
      disabled={disabled}
      onClick={onClick}
      style={{ 'marginBottom': marginBottom, 'marginRight': marginRight, 'opacity': opacity, 'padding': padding, 'width': width }}
      variant="contained"
    >
      <b style={{'fontFamily': 'BradescoSans-Bold'}}>{text}</b>
    </MaterialUiButton>
  )
}

StandardButton.propTypes = {
  disabled: PropTypes.bool,
  marginBottom: PropTypes.string,
  marginRight: PropTypes.string,
  onClick: PropTypes.func,
  opacity: PropTypes.string,
  padding: PropTypes.string,
  text: PropTypes.string.isRequired,
  width: PropTypes.string,
}

StandardButton.defaultProps = {
  disabled: false,
  marginBottom: '0px',
  marginRight: '0px',
  onClick: () => {},
  opacity: '1',
  padding: '10px 35px',
  width: 'auto',
}

export default StandardButton;
