const initialState = {
  message: '',
  show: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_SNACKBAR':
      return { ...state, message: action.payload, show: true };
    case 'CLOSE_SNACKBAR':
      return { ...state, message: '', show: false };
    default:
      return state;
  }
}
