const initialState = {
  actives: [],
  date: '',
  error: '',
  place: {
    code: 0,
    address: '',
    name: '',
  },
  status: 'done',
  time: {
    end: '',
    start: '',
  },
  consignedOrigin: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SUCCESS_RETRIEVE_STATUS':
      return {
        ...state,
        customer: {
          ...state.customer,
          id: action.payload.customerId
        },
        status: action.payload.status
      }
    case 'SUCCESS_SELECT_PROCEDURE':
      return {
        ...state,
        status: 'in_progress'
      }
    case 'SUCCESS_SCHEDULE':
      return {
        ...state,
        ...action.payload
      }
    case 'SUCCESS_SCHEDULING_CONFIRMATION':
      return { ...state, actives: action.payload };
    case 'SUCCESS_CANCEL':
      return { ...initialState };
    case 'SUCCESS_FIND_SCHEDULING_BY_CUSTOMER_ID':
      return { ...state, actives: action.payload }
    case 'SUCCESS_REMOVE_SCHEDULING':
      return { ...state, actives: [] };
    case 'SUCCESS_CHANGE_CONSIGNED_ORIGIN':
      return { ...state, consignedOrigin: action.payload };
    case 'SUCCESS_UPDATE_CONSIGNED_LIST':
      return { ...state, customer: { ...state.customer, consignedList: action.payload } };
    default:
      return state;
  }
}
