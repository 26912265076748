import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    background: 'white',
    border: '1px solid #fff',
    color: '#CC092F',
    borderRadius: 30,
    fontWeight: 600,
    fontSize: '1.5rem',
    height: '3.5rem',
    opacity: 1,
    padding: '10px 50px',
    textTransform: 'inherit',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      height: '2.8rem',
      padding: '10px 30px', // Ajuste do padding para telas menores que 'md'
    },
  },
  blackColor: {
    color: theme.palette.black,
  },
}));
