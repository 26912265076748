/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Grid, Snackbar } from '@material-ui/core';

import backgroundImg from 'images/bg/desktop/background.png';
import backgroundImgMobile from 'images/bg/mobile/bg.png';
import logo from 'images/logos/logo-bradesco.png';
import topLeftImg from 'images/bg/desktop/grafismo-esquerdo-superior.png';
import bottomRightImg from 'images/bg/desktop/grafismo-lado-direito-inferior.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    minHeight: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.neutral,
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${backgroundImg})`,
    [theme.breakpoints.down('md')]: {
      backgroundImage: `url(${backgroundImgMobile})`,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '10%',
    paddingRight: '8%',
    paddingTop: '55%',
    zIndex: '99',
    [theme.breakpoints.up('md')]: {
      paddingTop: '25%',
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '15%',
    }
  },
  topLeftImg: {
    top: '7%',
    left: '0%',
    height: 'auto',
    position: 'fixed',
    width: '50%',
    [theme.breakpoints.up('lg')]: {
      top: '3%',
      width: '25%',
    },
  },
  bottomRightImg: {
    top: '73.5%',
    left: '50.5%',
    height: 'auto',
    position: 'fixed',
    width: '50%',
    [theme.breakpoints.up('lg')]: {
      width: '20%',
      top: '51%',
      left: '80.5%',
    },
  },
  logo: {
    top: '4%',
    left: '72%',
    height: 'auto',
    position: 'fixed',
    width: '20%',
    [theme.breakpoints.up('lg')]: {
      left: '88%',
      width: '7%',
    },
  },
}));

function Layout1({ children }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const snack = useSelector((state) => state.snackbar);

  const closeSnackbar = () => {
    dispatch({ type: 'CLOSE_SNACKBAR' });
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <img alt="" className={classes.logo} src={logo} />
        <img alt="" className={classes.topLeftImg} src={topLeftImg} />
        <img alt="" className={classes.bottomRightImg} src={bottomRightImg} />
        <Grid className={classes.content} item style={{ 'width': '100%'}} xs={12}>
          {children}
        </Grid>
      </div>
      <Snackbar
        autoHideDuration={6000}
        message={snack.message}
        onClose={closeSnackbar}
        open={snack.show}
      />
    </React.Fragment>
  );
}

Layout1.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Layout1;
