/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button as MaterialUiButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'transparent',
    border: '1px solid #fff',
    borderRadius: 30,
    color: theme.palette.white,
    fontSize: '1rem',
    height: '3rem',
  }
}));

const TransparentButton = ({ fontSize, onClick, opacity, padding, text, width }) => {
  const classes = useStyles();

  return (
    <MaterialUiButton
      className={classes.root}
      color="primary"
      onClick={onClick}
      style={{ 'fontSize': fontSize, 'width': width, 'opacity': opacity, 'padding': padding, }}
      variant="contained"
    >
      {text}
    </MaterialUiButton>
  )
}

TransparentButton.propTypes = {
  fontSize: PropTypes.string,
  onClick: PropTypes.func,
  opacity: PropTypes.string,
  padding: PropTypes.string,
  text: PropTypes.string.isRequired,
  width: PropTypes.string,
}

TransparentButton.defaultProps = {
  fontSize: '1rem',
  onClick: () => {},
  opacity: '1',
  padding: '10px 35px',
  width: '100%',
}

export default TransparentButton;
