/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button as MaterialUiButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  noAutoWidth: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, transparent)`,
    border: '1px solid #fff',
    borderRadius: 30,
    fontSize: '1.5rem',
    height: '3rem',
    [theme.breakpoints.only('md')]: {
      width: '13rem',
    },
  },
  autoWidth: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, transparent)`,
    border: '1px solid #fff',
    borderRadius: 30,
    fontSize: '15rem',
    padding: '10px 35px',
    fontWeight: 600,
    height: '3rem',
  }
}));

const Button = ({ autoWidth, disabled, fontWeight, fontSize, onClick, opacity, text, width }) => {
  const classes = useStyles();

  return (
    <MaterialUiButton
      className={autoWidth ? classes.autoWidth : classes.noAutoWidth}
      color="primary"
      disabled={disabled}
      onClick={onClick}
      style={{ 'fontWeight': fontWeight, 'fontSize': fontSize, 'opacity': opacity, 'width': width }}
      variant="contained"
    >
      {text}
    </MaterialUiButton>
  )
}

Button.propTypes = {
  autoWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  onClick: PropTypes.func,
  opacity: PropTypes.string,
  text: PropTypes.string.isRequired,
  width: PropTypes.string,
}

Button.defaultProps = {
  autoWidth: false,
  disabled: false,
  fontWeight: '500',
  fontSize: '1rem',
  onClick: () => {},
  opacity: '1',
  width: '100%',
}

export default Button;
