import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { PrivateRouteWithLayout, RouteWithLayout } from './components';
import {
  Minimal as MinimalLayout,
  Layout4,
  Layout5,
  Layout6,
  Layout7,
  Layout8,
  Layout9,
  Layout10,
} from './layouts';

import MainLayout from 'layouts/Main';

const Account = React.lazy(() => import('./views/Account'));
const BankOffice = React.lazy(() => import('./views/BankOffice'));
const Benefits = React.lazy(() => import('./views/Benefits'));
const CodeConfirmation = React.lazy(() => import('./views/CodeConfirmation'));
const ContactConfirmation = React.lazy(() => import('./views/ContactConfirmation'));
const DigitalAccount = React.lazy(() => import('./views/DigitalAccount'));
const DigitalAccountMsg = React.lazy(() => import('./views/DigitalAccountMsg'));
const NotFound = React.lazy(() => import('./views/NotFound'));
const PersonalAttendance = React.lazy(() => import('./views/PersonalAttendance'));
const Procedure = React.lazy(() => import('./views/Procedure'));
const PrintScheduling = React.lazy(() => import('./views/PrintScheduling'));
const Quiz = React.lazy(() => import('./views/Quiz'));
const QuizInfo = React.lazy(() => import('./views/QuizInfo'));
const QuizMsg = React.lazy(() => import('./views/QuizMsg'));
const Search = React.lazy(() => import('./views/Search'));
const SelectSchedulingBankOffice = React.lazy(() => import('./views/SelectSchedulingBankOffice'));
const SelectProcess = React.lazy(() => import('./views/SelectProcess'));
const Scheduling = React.lazy(() => import('./views/Scheduling'));
const SchedulingConfirmation = React.lazy(() => import('./views/SchedulingConfirmation'));
const SchedulingSuccess = React.lazy(() => import('./views/SchedulingSuccess'));
const SearchScheduling = React.lazy(() => import('./views/SearchScheduling'));
const SmsConfirmation = React.lazy(() => import('./views/SmsConfirmation'));
const Success = React.lazy(() => import('./views/Success'));
const SuccessPartialProcess = React.lazy(() => import('./views/SuccessPartialProcess'));
const SuccessPersonalAttendanceRequest = React.lazy(() => import('./views/SuccessPersonalAttendanceRequest'));
const ThanksAccount = React.lazy(() => import('./views/ThanksAccount'));
const UseTerms = React.lazy(() => import('./views/UseTerms'));
const Welcome = React.lazy(() => import('./views/Welcome'));

const Routes = () => {

  return (
    <React.Suspense fallback={<h2>Aguarde...</h2>}>
      <Switch>
        <Redirect
          exact
          from="/"
          to="/welcome"
        />
        <RouteWithLayout
          component={Welcome}
          exact
          layout={MainLayout}
          layoutProps={{ logoAlign: 'right', showShield: false }}
          path="/welcome"
        />
        <RouteWithLayout
          component={Search}
          exact
          layout={MainLayout}
          path="/search"
        />
        <PrivateRouteWithLayout
          component={SelectProcess}
          exact
          layout={MainLayout}
          layoutProps={{ logoAlign: 'center', showShield: true }}
          path="/select-process"
        />
        <PrivateRouteWithLayout
          component={ContactConfirmation}
          exact
          layout={MainLayout}
          path="/contact-confirmation"
        />
        <PrivateRouteWithLayout
          component={SmsConfirmation}
          exact
          layout={MainLayout}
          path="/sms-confirmation"
        />
        <PrivateRouteWithLayout
          component={CodeConfirmation}
          exact
          layout={MainLayout}
          path="/code-confirmation"
        />
        <PrivateRouteWithLayout
          component={QuizInfo}
          exact
          layout={Layout7}
          path="/quiz-info"
        />
        <PrivateRouteWithLayout
          component={Quiz}
          exact
          layout={Layout9}
          path="/quiz"
        />
        <PrivateRouteWithLayout
          component={QuizMsg}
          exact
          layout={Layout5}
          path="/quiz-msg"
        />
        <PrivateRouteWithLayout
          component={UseTerms}
          exact
          layout={Layout6}
          path="/use-terms"
        />
        <PrivateRouteWithLayout
          component={Procedure}
          exact
          layout={Layout4}
          path="/procedure"
        />
        <PrivateRouteWithLayout
          component={DigitalAccount}
          exact
          layout={Layout5}
          path="/digital-account"
        />
        <PrivateRouteWithLayout
          component={DigitalAccountMsg}
          exact
          layout={Layout5}
          path="/digital-account-msg"
        />
        <PrivateRouteWithLayout
          component={Account}
          exact
          layout={Layout6}
          path="/account"
        />
        <PrivateRouteWithLayout
          component={Scheduling}
          exact
          layout={Layout8}
          path="/scheduling"
        />
        <PrivateRouteWithLayout
          component={Benefits}
          exact
          layout={Layout4}
          path="/benefits"
        />
        <PrivateRouteWithLayout
          component={SearchScheduling}
          exact
          layout={Layout8}
          path="/search-scheduling"
        />
        <PrivateRouteWithLayout
          component={SchedulingConfirmation}
          exact
          layout={Layout8}
          path="/scheduling-confirmation"
        />
        <RouteWithLayout
          component={SchedulingSuccess}
          exact
          layout={MainLayout}
          layoutProps={{ showShield: false }}
          path="/scheduling-success"
        />
        <PrivateRouteWithLayout
          component={PersonalAttendance}
          exact
          layout={MinimalLayout}
          path="/personal-attendance"
        />
        <PrivateRouteWithLayout
          component={BankOffice}
          exact
          layout={Layout8}
          path="/bank-office"
        />
        <PrivateRouteWithLayout
          component={SelectSchedulingBankOffice}
          exact
          layout={Layout8}
          path="/select-scheduling-bank-office"
        />
        <RouteWithLayout
          component={SuccessPartialProcess}
          exact
          layout={MinimalLayout}
          path="/success-partial-process"
        />
        <RouteWithLayout
          component={SuccessPersonalAttendanceRequest}
          exact
          layout={MinimalLayout}
          path="/success-personal-request"
        />
        <RouteWithLayout
          component={PrintScheduling}
          exact
          layout={Layout10}
          path="/print-scheduling"
        />
        <RouteWithLayout
          component={Success}
          exact
          layout={MainLayout}
          layoutProps={{ showShield: false }}
          path="/success"
        />
        <RouteWithLayout
          component={NotFound}
          exact
          layout={MinimalLayout}
          path="/not-found"
        />
        <PrivateRouteWithLayout
          component={ThanksAccount}
          exact
          layout={Layout5}
          path="/thanks-account"
        />
        <Redirect to="/not-found" />
      </Switch>
    </React.Suspense>
  );
};

export default Routes;
