import axios from 'axios';
import endpoint from 'endpoint';

export default {
  findSchedulingTime: async (data) => {
    return axios.get(`${endpoint.REACT_APP_SCHEDULING_ENDPOINT}/v1/scheduling-time?projectId=${data.projectId}&date=${data.date}&utc=${data.utc}&bankOfficeId=${data.bankOfficeId}`);
  },
  insert: async (data) => {
    return axios.post(`${endpoint.REACT_APP_SCHEDULING_ENDPOINT}/v1/scheduling?utc=${data.utc}`, data);
  },
  findByCustomerAndProject: async (projectId) => {
    return axios.get(`${endpoint.REACT_APP_SCHEDULING_ENDPOINT}/v1/scheduling/customer/project/${projectId}`);
  },
  remove: async (id) => {
    return axios.delete(`${endpoint.REACT_APP_SCHEDULING_ENDPOINT}/v1/scheduling/` + id);
  },
  requestPersonalAttendance: async (data) => {
    return axios.post(`${endpoint.REACT_APP_SCHEDULING_ENDPOINT}/v1/scheduling/request-personal-attendance`, data);
  },
  requestSendConfirmationMail: async (data) => {
    return axios.post(`${endpoint.REACT_APP_SCHEDULING_ENDPOINT}/v1/scheduling/send-confirmation-mail`, data);
  },
  findFirstSchedule: async (projectId) => {
    return axios.get(`${endpoint.REACT_APP_SCHEDULING_ENDPOINT}/v1/scheduling-time/first-schedule?projectId=${projectId}`);
  }
}
