import React from 'react';

import CustomMaskInput from 'components/CustomMaskInput/CustomMaskInput';

const DocumentMaskInput = (props) => {
  const { ...rest } = props;
  const mask = [/[1-9]/, /[1-9]/, /[1-9]/, '.', /[1-9]/, /[1-9]/, /[1-9]/, '.', /[1-9]/, /[1-9]/, /[1-9]/, '-', /\w/, /\w/];

  return (
    <CustomMaskInput
      {...rest}
      mask={mask}
    />
  )
}

export default DocumentMaskInput;
