import React from 'react';

import CustomMaskInput from 'components/CustomMaskInput/CustomMaskInput';

const PhoneMaskInput = (props) => {
  const { ...rest } = props;
  const mask = ['(', /[1-9]/, /[1-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  return (
    <CustomMaskInput
      {...rest}
      mask={mask}
    />
  )
}

export default PhoneMaskInput;
