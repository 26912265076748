/* eslint-disable no-undef */
import { all, call, takeLatest, put, select } from 'redux-saga/effects';

import customerService from 'service/customerService';
import schedulingService from 'service/schedulingService';
import bankOfficeService from 'service/bankOfficeService';

import moment from 'moment';

import axios from 'axios';

import {
  responseErrorApi,
  storage as storageHelper,
  timezone,
} from 'helpers';

function* requestRetrieveStatus(action) {
  const storage = storageHelper.get();
  let data = {
    customerId: '',
    status: 'not_started',
  };

  if (storage !== null) {
    data = {
      customerId: storage.customerId,
      status: storage.status,
    }
  }

  yield put({ type: 'SUCCESS_RETRIEVE_STATUS', payload: data });

  if (action.payload && action.payload.onSuccess) {
    action.payload.onSuccess(storage);
  }
}

function* requestFindCustomerById(action) {
  if (!action.payload.token || action.payload.token === '') {
    action.payload.onError('Token inválido');
    return;
  }

  if (!action.payload.id || action.payload.id === '') {
    action.payload.onError('Documento inválido');
    return;
  }

  try {
    const response = yield call(customerService.findById, action.payload.id, action.payload.token);
    const customer = response.data;

    if (customer.id === null) {
      if (action.payload && action.payload.onNoContent) {
        action.payload.onNoContent();
      }
      return;
    }

    yield put({ type: 'SUCCESS_FIND_CUSTOMER', payload: customer });
    yield put({ type: 'SUCCESS_FIND_PROJECTS', payload: customer });

    let nextUrl = '/sms-confirmation';

    if (customer.selectProjectRequired) {
      nextUrl = '/select-process';
    } else {
      yield put({ type: 'SUCCESS_SELECTED_PROJECT', payload: customer.projects[0] });

      if (customer.updateContactRequired) {
        nextUrl = '/contact-confirmation';
      }
    }

    if (action.payload && action.payload.onSuccess) {
      action.payload.onSuccess(nextUrl);
    }
  } catch (e) {
    const errors = responseErrorApi.getErrorList(e);

    if (action.payload && action.payload.onError) {
      action.payload.onError(errors);
    }
  }
}

function* requestUpdateCustomerContact(action) {
  try {
    const { customer, projects } = yield select(state => state);
    const values = action.payload.data;

    const data = {
      id: customer.id,
      phone: values.phone,
      email: values.email,
      projectName: projects.selected.name,
    };

    yield call(customerService.updateContact, data);

    if (action.payload && action.payload.onSuccess) {
      action.payload.onSuccess();
    }
  } catch (e) {
    const errors = responseErrorApi.getErrorList(e);

    if (action.payload && action.payload.onError) {
      action.payload.onError(errors);
    }
  }
}

function* requestValidateCodeConfirmation(action) {
  try {
    const customer = yield select((state) => state.customer);
    const projects = yield select((state) => state.projects);

    const data = {
      id: customer.id,
      code: action.payload.data,
      projectId: projects.selected.id,
    };

    const response = yield call(customerService.validateCodeConfirmation, data);

    let nextUrl = '/benefits';

    if (customer.projectQuizRequired !== null) {
      nextUrl = '/quiz-info';
    }

    axios.defaults.headers.common.authorization = `Bearer ${response.data.token}`;

    if (action.payload && action.payload.onSuccess) {
      action.payload.onSuccess(nextUrl);
    }
  } catch (e) {
    if (action.payload && action.payload.onError) {
      if (e.response) {
        action.payload.onError(e.response.data);
      } else {
        action.payload.onError(e);
      }
    }
  }
}

function* requestSchedulings(action) {
  try {
    const data = {
      projectId: action.payload.projectId,
      date: action.payload.date,
      utc: timezone.uffset,
      bankOfficeId: action.payload.bankOfficeId,
    };

    const response = yield call(schedulingService.findSchedulingTime, data);

    if (action.payload && action.payload.onSuccess) {
      action.payload.onSuccess(response.data);
    }
  } catch (e) {
    action.payload.onError(e);
  }
}

function* requestSchedule(action) {
  try {
    yield put({ type: 'SUCCESS_SCHEDULE', payload: action.payload.data });

    if (action.payload && action.payload.onSuccess) {
      action.payload.onSuccess();
    }
  } catch (e) {
    action.payload.onError(e);
  }
}

function* requestSchedulingConfirmation(action) {
  try {
    const projects = yield select((state) => state.projects);
    const scheduling = yield select(state => state.scheduling);

    const data = {
      date: moment(scheduling.date).format('YYYY-MM-DD'),
      start: scheduling.time.start,
      end: scheduling.time.end,
      projectId: projects.selected.id,
      placeId: scheduling.place.id,
      utc: timezone.uffset,
      bankOfficeId: scheduling.place.id,
    };

    yield call(schedulingService.insert, data);

    data.place = {
      address: scheduling.place.address,
      name: scheduling.place.name,
    };

    yield put({ type: 'SUCCESS_SCHEDULING_CONFIRMATION', payload: [data] });

    if (action.payload && action.payload.onSuccess) {
      action.payload.onSuccess();
    }
  } catch (e) {
    console.error(e);

    if (action.payload && action.payload.onError) {
      action.payload.onError(e.response.data);
    } else {
      action.payload.onError();
    }
  }
}

function* requestCancel(action) {
  yield put({ type: 'SUCCESS_CANCEL' });
  yield put({ type: 'REMOVE_CUSTOMER' });
  yield put({ type: 'RESET_FIND_PROJECTS' });

  if (action.payload && action.payload.onSuccess) {
    action.payload.onSuccess();
  }
}

function* requestFindSchedulingListByCustomerId(action) {
  try {
    const projects = yield select((state) => state.projects);
    const response = yield call(schedulingService.findByCustomerAndProject, projects.selected.id);

    yield put({ type: 'SUCCESS_FIND_SCHEDULING_BY_CUSTOMER_ID', payload: response.data });

    if (action.payload && action.payload.onSuccess) {
      action.payload.onSuccess(response.data);
    }
  } catch (e) {
    console.error(e);

    if (action.payload && action.payload.onError) {
      action.payload.onError(e.response.data);
    } else {
      action.payload.onError();
    }
  }
}

function* requestRemoveScheduling(action) {
  try {
    yield call(schedulingService.remove, action.payload.data);

    yield put({ type: 'SUCCESS_REMOVE_SCHEDULING' });

    if (action.payload && action.payload.onSuccess) {
      action.payload.onSuccess();
    }
  } catch (e) {
    console.error(e);

    if (action.payload && action.payload.onError) {
      action.payload.onError(e.response.data);
    } else {
      action.payload.onError();
    }
  }
}

function* requestPersonalAttendance(action) {
  try {
    const scheduling = yield select((state) => state.scheduling);
    const projects = yield select((state) => state.projects);
    const data = {
      customerId: scheduling.customer.id,
      projectId: projects.selected.id,
      text: action.payload.data,
    };

    yield call(schedulingService.requestPersonalAttendance, data);

    // storageHelper.remove();

    if (action.payload && action.payload.onSuccess) {
      action.payload.onSuccess();
    }
  } catch (error) {
    if (action.payload && action.payload.onError) {
      action.payload.onError(e.response.data);
    } else {
      console.error(e);
    }
  }
}

function* requestChangeConsignedOrigin(action) {
  yield put({ type: 'SUCCESS_CHANGE_CONSIGNED_ORIGIN', payload: action.payload.value });

  if (action.payload.onComplete) {
    action.payload.onComplete();
  }
}

function* requestUpdateConsignedList(action) {
  yield put({ type: 'SUCCESS_UPDATE_CONSIGNED_LIST', payload: action.payload.value });

  if (action.payload.onComplete) {
    action.payload.onComplete();
  }
}

function* requestUpdateAccount(action) {
  try {
    const payload = action.payload.data;

    yield call(customerService.updateAccount, payload);

    if (action.payload && action.payload.onSuccess) {
      action.payload.onSuccess();
    }
  } catch (e) {
    if (action.payload && action.payload.onError) {
      action.payload.onError();
    }
  }
}

function* requestFindNearBankOffices(action) {
  try {
    const projects = yield select((state) => state.projects);
    const response = yield call(bankOfficeService.findNearBankOffices, projects.selected.id,
      action.payload.zipCode);

    if (action.payload && action.payload.onSuccess) {
      action.payload.onSuccess(response);
    }
  } catch (e) {
    console.error(e);
    if (action.payload && action.payload.onError) {
      action.payload.onError(response);
    }
  }
}

function* requestSaveBankOffice(action) {
  try {
    const bankOfficeCode = action.payload.bankOfficeCode;

    yield call(customerService.saveBankOffice, bankOfficeCode);

    if (action.payload && action.payload.onSuccess) {
      action.payload.onSuccess();
    }
  } catch (e) {
    console.error(e);
    if (action.payload && action.payload.onError) {
      action.payload.onError(response);
    }
  }
}

function* requestSaveQuiz(action) {
  try {
    const projects = yield select((state) => state.projects);
    const quiz = {
      ...action.payload.quiz,
      projectId: projects.selected.id,
    };

    let hasErrors = false;

    if (quiz.answer1.option === 16 && (quiz.answer1.otherBank === undefined || quiz.answer1.otherBank === '')) {
      hasErrors = true;
    }

    Object.getOwnPropertyNames(quiz).forEach(function (prop) {
      if (prop !== 'answer3' && quiz[prop].option === 0) {
        hasErrors = true;
      }
    });

    if (quiz.answer6.overdraftLimit.option === 0 || quiz.answer6.personalCredit.option === 0) {
      hasErrors = true;
    }

    if (quiz.answer11.option === 1 && quiz.answer11.values.length === 0) {
      hasErrors = true;
    }

    if (hasErrors === true) {
      yield put({ type: 'SHOW_SNACKBAR', payload: 'Por favor, responda todas as perguntas' });
      return;
    }

    yield call(customerService.saveQuiz, quiz);

    if (action.payload && action.payload.onSuccess) {
      action.payload.onSuccess();
    }
  } catch (e) {
    console.error(e);
    if (action.payload && action.payload.onError) {
      action.payload.onError(response);
    }
  }
}

export default function* root() {
  yield all([
    takeLatest('REQUEST_RETRIEVE_STATUS', requestRetrieveStatus),
    takeLatest('REQUEST_FIND_CUSTOMER_BY_ID', requestFindCustomerById),
    takeLatest('REQUEST_UPDATE_CUSTOMER_CONTACT', requestUpdateCustomerContact),
    takeLatest('REQUEST_VALIDATE_CODE_CONFIRMATION', requestValidateCodeConfirmation),
    takeLatest('REQUEST_SCHEDULINGS', requestSchedulings),
    takeLatest('REQUEST_SCHEDULE', requestSchedule),
    takeLatest('REQUEST_SCHEDULING_CONFIRMATION', requestSchedulingConfirmation),
    takeLatest('REQUEST_CANCEL', requestCancel),
    takeLatest('REQUEST_FIND_SCHEDULING_BY_CUSTOMER_AND_PROJECT', requestFindSchedulingListByCustomerId),
    takeLatest('REQUEST_REMOVE_SCHEDULING', requestRemoveScheduling),
    takeLatest('REQUEST_PERSONAL_ATTENDANCE', requestPersonalAttendance),
    takeLatest('REQUEST_CHANGE_CONSIGNED_ORIGIN', requestChangeConsignedOrigin),
    takeLatest('REQUEST_UPDATE_CONSIGNED_LIST', requestUpdateConsignedList),
    takeLatest('REQUEST_UPDATE_ACCOUNT', requestUpdateAccount),
    takeLatest('REQUEST_FIND_NEAR_BANK_OFFICES', requestFindNearBankOffices),
    takeLatest('REQUEST_SAVE_BANK_OFFICE', requestSaveBankOffice),
    takeLatest('REQUEST_SAVE_QUIZ', requestSaveQuiz),
  ]);
}
