/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@material-ui/core';

const Minimal = props => {
  const { children } = props;

  const dispatch = useDispatch();
  const snack = useSelector((state) => state.snackbar);

  const closeSnackbar = () => {
    dispatch({ type: 'CLOSE_SNACKBAR' });
  }

  return (
    <React.Fragment>
      {children}
      <Snackbar
        autoHideDuration={6000}
        message={snack.message}
        onClose={closeSnackbar}
        open={snack.show}
      />
    </React.Fragment>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Minimal;
