const initialState = {
  id: null,
  name: '',
  email: '',
  updateContactRequired: false,
  selectProjectRequired: false,
  projectQuizRequired: null,
  hasAccount: false,
  schedulingMethod: {},
  schedulingPeriod: null,
  schedulingPeriodExtraDays: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SUCCESS_FIND_CUSTOMER':
      return {
        ...state,
        id: action.payload.id,
        updateContactRequired: action.payload.updateContactRequired,
        selectProjectRequired: action.payload.selectProjectRequired,
        projectQuizRequired: action.payload.projectQuizRequired,
        hasAccount: action.payload.hasAccount,
        schedulingMethod: action.payload.schedulingMethod,
        schedulingPeriod: action.payload.schedulingPeriod,
        schedulingPeriodExtraDays: action.payload.schedulingPeriodExtraDays,
      };
    case 'FAILED_FIND_CUSTOMER':
      return { ...initialState }
    case 'REMOVE_CUSTOMER':
      return { ...initialState }
    default:
      return state;
  }
}
