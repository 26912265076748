import { combineReducers } from 'redux';

import customer from './customer';
import projects from './projects';
import scheduling from './scheduling';
import snackbar from './snackbar';

export default combineReducers({
  customer,
  projects,
  scheduling,
  snackbar,
});
