/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Grid, Snackbar } from '@material-ui/core';

import backgroundImg from 'images/bg/desktop/background-l6.png';
import backgroundImgMobile from 'images/bg/mobile/bg-l6.png';
import logo from 'images/logos/logo-bradesco.png';
import topLeftImg from 'images/bg/desktop/grafismo-esquerdo-superior.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.neutral,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${backgroundImg})`,
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      backgroundImage: `url(${backgroundImgMobile})`,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '16%',
    paddingRight: '8%',
    paddingTop: '30%',
    zIndex: '99',
    [theme.breakpoints.down('md')]: {
      paddingTop: '33%',
      paddingLeft: '9%',
      paddingRight: '-9%',
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '40%',
      paddingLeft: '5%',
      paddingRight: '5%',

    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '5%',
      paddingLeft: '22%',
    }
  },
  topLeftImg: {
    top: '4%',
    left: '61%',
    height: 'auto',
    position: 'absolute',
    width: '40%',
    transform: 'scaleX(-1)',
    [theme.breakpoints.up('lg')]: {
      left: '0%',
      top: '3%',
      width: '25%',
      transform: 'scaleX(1)',
    },
  },
  logo: {
    top: '4%',
    left: '10%',
    height: 'auto',
    position: 'absolute',
    width: '20%',
    [theme.breakpoints.up('lg')]: {
      left: '88%',
      width: '7%',
    },
  },
}));

function Layout6({ children }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const snack = useSelector((state) => state.snackbar);

  const closeSnackbar = () => {
    dispatch({ type: 'CLOSE_SNACKBAR' });
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <img alt="" className={classes.logo} src={logo} />
        <img alt="" className={classes.topLeftImg} src={topLeftImg} />
        <Grid className={classes.content} item style={{ 'width': '100%' }} xs={12}>
          {children}
        </Grid>
      </div>
      <Snackbar
        autoHideDuration={6000}
        message={snack.message}
        onClose={closeSnackbar}
        open={snack.show}
      />
    </React.Fragment>
  );
}

Layout6.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Layout6;
