/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { StandardButton } from 'components';
import { Typography } from '@material-ui/core';

import alertIcon from 'images/icon_alert.svg';
import smsIcon from 'images/icon_sms.svg';
import attendantIcon from 'images/atendente_ico.svg';
import { If } from 'helpers';

import htmlReactParser from 'html-react-parser';

const useStyles = makeStyles(theme => ({
  root: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    height: 'auto',
    left: 0,
    minHeight: '100%',
    opacity: '.95',
    padding: '4rem 2rem',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  iconBox: {
    marginBottom: '2.5rem',
    textAlign: 'center',
  },
  icon: {
    height: 'auto',
    width: '8rem',
  },
  description: {
    color: theme.palette.white,
    lineHeight: '1.5',
  },
  message: {
    color: theme.palette.white,
    fontWeight: 'bold',
    lineHeight: '1.75',
  },
  suggestions: {
    color: theme.palette.white,
    lineHeight: '1.75',
    marginTop: '2rem',
    opacity: '0.75',
  },
  warning: {
    color: '#FFD700',
    lineHeight: '1.75',
    fontWeight: 'bold',
    marginBottom: '2rem',
    marginTop: '2rem',
  },
  phone: {
    color: theme.palette.white,
    fontWeight: 'bold',
    lineHeight: '1.75',
  },
  buttonBox: {
    marginTop: '3rem',
    textAlign: 'center',
  },
}));

const Alert = ({ description, icon, message, onClick, suggestions, textButton, visible, warning }) => {
  const classes = useStyles();

  const getIcon = (name) => {
    switch (name) {
      case 'alert':
        return alertIcon;
      case 'sms':
        return smsIcon;
      case 'attendant':
        return attendantIcon;
      default:
        return alertIcon;
    }
  }

  return (
    <div className={classes.root} style={{ display: visible ? 'block' : 'none' }}>
      <div className={classes.iconBox}>
        <img alt="" className={classes.icon} src={getIcon(icon)} />
      </div>
      <Typography align="center" className={classes.message} display="block" variant="h2">{message}</Typography>
      <Typography align="center" className={classes.description} display="block" variant="h4">{description}</Typography>
      <If test={warning !== null}>
        <Typography align="center" className={classes.warning} display="block" variant="body1">
          {warning}
        </Typography>
      </If>
      <Typography align="center" className={classes.suggestions} display="block" variant="body1">
        {htmlReactParser(suggestions)}
      </Typography>
      <Typography align="center" className={classes.phone} display="block" variant="body1">
        3003-0330 - Região metropolitana
      </Typography>
      <Typography align="center" className={classes.phone} display="block" variant="body1">
        0800-208-0330 - Demais regiões
      </Typography>
      <div className={classes.buttonBox}>
        <StandardButton onClick={onClick} text={textButton} />
      </div>
    </div>
  )
}

Alert.propTypes = {
  description: PropTypes.string.isRequired,
  icon: PropTypes.string,
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  suggestions: PropTypes.string,
  textButton: PropTypes.string,
  visible: PropTypes.bool,
  warning: PropTypes.string,
};

Alert.defaultProps = {
  icon: 'alert',
  suggestions: 'Caso o problema persista, entre em contato com a nossa Central de Atendimento:',
  textButton: 'Tentar novamente',
  visible: false,
  warning: null,
}

export default Alert;
