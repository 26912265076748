import axios from 'axios';
import endpoint from 'endpoint';

export default {
  updateContact: async (data) => {
    return axios.put(`${endpoint.REACT_APP_SCHEDULING_ENDPOINT}/v1/customers/contact`, data);
  },
  validateCodeConfirmation: async (data) => {
    return axios.post(`${endpoint.REACT_APP_SCHEDULING_ENDPOINT}/v1/customers/code-confirmation/validate`, data);
  },
  updateAccount: async (data) => {
    return axios.post(`${endpoint.REACT_APP_SCHEDULING_ENDPOINT}/v1/customers/account`, data);
  },
  saveBankOffice: async (bankOfficeCode) => {
    return axios.put(`${endpoint.REACT_APP_SCHEDULING_ENDPOINT}/v1/customers/bank-office/${bankOfficeCode}`);
  },
  saveQuiz: async (quiz) => {
    return axios.put(`${endpoint.REACT_APP_SCHEDULING_ENDPOINT}/v2/customers/quiz`, quiz);
  },
  findById: async (id, token) => {
    return axios.get(`${endpoint.REACT_APP_SCHEDULING_ENDPOINT}/v1/customers/${id}?token=${token}`);
  },
}
