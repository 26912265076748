import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import ptBrLocale from 'date-fns/locale/pt-BR';

class PtLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, 'dd/MM/yyyy', { locale: this.locale });
  }
}

const localeMap = {
  pt: ptBrLocale
};

const localeUtilsMap = {
  pt: PtLocalizedUtils,
};

const localeFormatMap = {
  pt: 'dd/MM/yyyy',
};

const localeCancelLabelMap = {
  pt: 'Cancelar'
};

export { localeMap, localeUtilsMap, localeFormatMap, localeCancelLabelMap };
