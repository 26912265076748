import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';

import './NoticeModal.css';

function NoticeModalContainer({ children, maxDate }) {
  const currentDate = new Date();

  return currentDate.getTime() <= maxDate.getTime() ? children : null
}

function Notice() {

  const [showModal, setShowModal] = React.useState(true);

  const onCloseModal = React.useCallback(() => {
    setShowModal((visible) => !visible);
  }, []);

  return (
    <Modal onClose={onCloseModal} visible={showModal}>
      <div className="header-notice notice">Atenção</div>
      <div className="notice">
        Senhores usuários, a partir do dia 29/12/2022 após as 20h00 até o dia 01/01/2023 às 22h00 o link e a plataforma do sistema
        www.agendabanco.com.br ficará fora do ar.
      </div>
      <div className="notice">
        Realizaremos uma manutenção em ambos os servidores que estão localizados no Brasil e nos Estados Unidos com o objetivo de
        melhorar ainda mais os serviços que são utilizados pelo BANCO BRADESCO, cliente exclusivo deste link.
      </div>
      <div className="footer-notice notice">Agradecemos desde já pela compreensão e parceria de sempre.</div>
    </Modal>
  )
}

function NoticeModal() {
  return (
    <NoticeModalContainer maxDate={new Date(2023, 0, 2, 1, 0, 0)}>
      <Notice />
    </NoticeModalContainer>
  )
}

NoticeModalContainer.propTypes = {
  children: PropTypes.any,
  maxDate: PropTypes.any,
}

export default NoticeModal;
