import React from 'react';
import PropTypes from 'prop-types';

import './Modal.css';

function Modal({ children, onClose, visible, }) {
  return (
    <div className="modal" style={{ 'display': visible ? 'block' : 'none '}}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        { children }
      </div>

    </div>
  )
}

Modal.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
}

Modal.defaultProps = {
  visible: false,
}

export default Modal;
